export default defineNuxtRouteMiddleware(async (to, from) => {
  const { loggedIn } = storeToRefs(useAuthStore());
  const { userData } = storeToRefs(useUserStore());

  const previousRoute = from.fullPath || '/dashboard/settings';

  if (!loggedIn.value) {
    return await navigateTo('/login');
  }

  if (userData.value.isSuperuser) {
    return;
  }

  return await navigateTo(previousRoute);
});
